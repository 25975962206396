/*!* Overlay with Header *!*/
.welcome {
    /* Element Positioning */
    position: absolute;
    width: fit-content;
    transform: translateX(-50%);    /* Center Element */
    left: 50%;
    top: calc(8vw + 1vh);
    z-index: 2;

    /* Text Positioning */
    font-size: 3vw;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;

    /* Text Styling */
    color: white;
    text-shadow: 2px 2px 3px black;
}

.next-line {
    margin-left: -0.7vw;
}

.MDM {
    /* Text Positioning */
    position: absolute;
    width: 100%;
    height: fit-content;

    /* Center Element*/
    transform: translateX(-50%);
    left: 50%;
    top: calc(10vw + 7.5vh);

    /*top: -57vw; left: 1vw;*/


    /* Center Text */
    text-align: center;
    /*margin-top: -79vw;*/
    /*margin-bottom: 20vw;*/
    /*left: 50%;*/

    /* Text Styling */
    color: white;
    font-size: calc(7vw + 1vh);
    font-weight: normal !important;
    text-shadow: 3px 3px 5px black;
    opacity: 1;

    line-height: 9vw;
    z-index: 2;
}

.hosts {
    /* Text Positioning */
    position: relative;
    /*top: -8vw;*/
    margin: calc(24vw + 12vh) 8vw 3vw;
    padding-left: 1vw;
    line-height: 3vw;
    z-index: 2;

    /* Text Styling */
    font-size: calc(1.5vw + 0.8vh);
    font-weight: lighter;
    display: flex;
    justify-content: space-between;

}

.social img {
    /* Element Positioning */
    position: relative;
    margin: 0.5vw;
    left: 94.5vw;
    top: -16vw;
    z-index: 2;

    /* Images */
    height: 4vw;
    display: flex;
    flex-direction: column;
    opacity: 80%;
}

hr {
    position: relative;
    height: 1px;
    color: #D6BBBC;
    width: 90%;
    margin: -0.5rem 5%;
    z-index: 3;
}

/*!* First section under headers *!*/
.about {
    /* Element Positioning */
    position: relative;
    display: flex;
    /*margin: -3vw 7.55vw 8vw;*/

    /*gap: 2vw;*/
    gap: 1vw;
    z-index: 1;

    /*border: solid 0.1vw #aea49c;*/
    /*border-radius: 10px;*/

    border-top: solid 0.1vw #aea49c;
    border-bottom: solid 0.1vw #aea49c;
    padding: 3vw 2vw;
    margin: -1vw 5% -1vw;
}
.hello-logo {
    background-color: #f4f0ed;
}

.about-general {
    width: 48%;
    margin: 0.5vw;
    background: linear-gradient(0deg, #D0c9c3, #f4f0ed, #f4f0ed);
    color: #1B1D28;
    font-size: calc(1.2vw + 0.53vh);
    padding: 0 2vw 3vw 3vw;
    border-radius: 10px;
}

.about h1 {
    margin: 2vw 0 0;
    text-align: center;
    font-weight: normal;
}
.about-this-year h2 {
    margin: 1.5vw 0 1vw;
    font-size: 3vw;
    font-weight: normal;
}

.about-this-year {
    text-shadow: 1.5px 1.5px 3px black;
    width: 48%;
    margin: 0.5vw;
    text-align: center;
    /*background: rgba(0, 0, 0, 0.5);*/
    /*border-radius: 10px;*/
}

.about-this-year h1 {
    letter-spacing: 1vw;
}
.about-this-year h3 {
    margin: 0;
    font-size: 2vw;
    font-weight: normal;
}

.about p {padding: 2vw 2% 0;}


.partnership {
    /*color: #D0c9c3;*/
    color: #e2dcd7;
    padding-right: 1vw;
    position: relative;
    display: flex;
    width: fit-content;
    margin: 2.3vw auto;
    font-size: calc(5vw + 1vh);
    text-align: center;
    align-items: center;
    font-weight: lighter;
    /*background: #6e0dd0;*/
    gap: 1.5vw;
    /*text-shadow: 1.5px 1.5px 3px black;*/

}

.partnership img {
    height: calc(7vw + 0.5vh);
}

.more-links {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5vw;
    /*margin-top: -1vw;*/
}
.more-links img {
    max-height: 6vw;
    margin-top: 0.1vw;
    /*margin-left: -2vw;*/
}

#insta img{
    margin-top: 0.2vw;
    width: 4.8vw;
    /*margin: 0.5vw;*/

}

.information {
    text-decoration: none;
    text-align: center;
    padding: 1vw 2.3vw;
    border: solid #5A0000;
    border-width: 0.1vw;
    color: #f4f0ed;
    font-weight: lighter;
    border-radius: 10px;
    font-size: 2vw;
    background-image: linear-gradient(0deg, #5A0000, darkred, #5A0000);
    /*background-color: #1B1D28;*/
}

.information:hover {
    background-image: linear-gradient(0deg, #5A0000, darkred, #5A0000);
    font-size: 2.1vw;
    padding: 0.9vw 2vw;
    border: solid #5A0000;
}


/*!* Highlighted Info Container *!*/
.more-info {
    position: relative;
    margin: 10vw 1vw 10vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    z-index: 2;
}

/*!* Individual Boxes *!*/
.info-box {
    position: relative;
    background: linear-gradient(0deg, #1B1D28, #303346, #1B1D28);
    color: #f4f0ed;
    height: calc(20vw + 10vh);
    width: calc(20vw + 10vh);
    min-height: fit-content;
}

.info-box h4 {
    text-align: center;
    text-shadow: 3px 3px 5px black;
    font-size: calc(2vw + 3vh);
    font-weight: lighter;
    margin: 5% 0 2%;
}

.info-box li {
    padding: 1.25vw 1vw;
    margin: 0 1vw;
    list-style: none;
    text-align: center;
    font-size: calc(0.6vw + 1.25vh);
}

#workshop li {padding: 0.75vw;}

/*!* Fancy Border *!*/
.info-box::before, .info-box::after {
    content: "";
    position: absolute;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: 4px solid darkred;
}
.info-box::before {left: -10px; top: 0;}
.info-box::after {left: 0; top: -10px;}

.year-specific {
    /* Element Positioning */
    position: relative;
    margin: 1vw;
    padding: 0 4vw;

    /* Positioning Within Element */
    display: flex;
    flex-wrap: wrap;
    /*padding: 3%;*/
    gap: 1rem;
    /*background-color: #6e0dd0;*/

    /* Text */
    color: #f4f0ed;
    font-size: 1.6rem;
    z-index: 1;

    /*border-top: solid 2px white;*/
    /*background-color: #6e0dd0;*/
    /*background: linear-gradient(90deg, transparent, #191923, #191923, transparent);*/
}

.countdown {

    /*    !* Center Element*!*/
        transform: translateX(-50%);
        left: 50%;
        /*top: calc(3vw + 7vh);*/
    /*margin: -5*/

}

.year-specific div {
    width: 43.3vw;
}

.schools {
    /* Element Positioning */
    position: relative;
    margin: -1vw 5% -1vw;

    /* Styling Element */
    padding: 3vw 2vw;
    border-top: solid 0.1vw #aea49c;
    border-bottom: solid 0.1vw #aea49c;

    /* Arrange inside container */
    display: flex;
    flex-wrap: wrap;
    column-gap: 3%;
    row-gap: 2rem;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.schools h2 {
    width: 100%;
    font-weight: normal;
    font-size: 6vw;
    text-align: center;
    margin: 0.5vw auto 1vw;
}

.schools img {
    height: 16vw;
    background: radial-gradient(white, white, white, #f4f0ed, #D0c9c3,  white);
    padding: 0.5vw;
    border-radius: 50%;
}

/* Mobile Device Formatting */
@media only screen and (max-device-width: 769px) {
    .welcome {top: 11.5vh;}
    .hosts {
        top: -8vw;
        line-height: 1rem;
    }
    .social img{
        top: -24vw;
        left: 90vw;
        height: 2rem;
    }
    .about {
        margin: -18vw 2vw 10vw;
        padding: 2vw 2vw 2rem;
        border-top: none;
        border-left: solid 0.5px #f4f0ed;
        border-right: solid 0.5px #f4f0ed;
    }
    .about h1 { padding-bottom: 1rem}
    .about p {
        margin: auto 0;
        font-size: 2vh;
        font-weight: lighter;
    }
    .info-box {
        height: 20rem;
        width: 20rem;
    }
    .info-box h4 {
        font-size: 3rem;
    }
    .info-box li {
        padding: 1.75rem 1rem 0 1.5rem;
        font-size: 1rem;
        text-align: center;
    }
    #workshop li{padding-top: 1.25rem;}
    .info-box::before, .info-box::after {
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        border: 2px solid darkred;
    }
    .info-box::before {left: -8px; top: 0;}
    .info-box::after {left: 0; top: -8px;}

    .partnership {
        height: calc(16vw + 17vh);
    }
    .partnership img {
        height: 4rem;
    }
}
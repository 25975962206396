.schedule-container {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: row;
    gap: 4%;
    justify-content: center;
    padding: 0 3%;
}

.day {
    position: relative;
    display: flex;
    /*margin: 2%;*/
    padding: 1vw;
    height: fit-content;
    width: 30%;
    background: linear-gradient(30deg, #5A0000, darkred, #5A0000);
    border-radius: 8px;
}

.day h3 {
    margin-right: 1vw;
    /*text-orientation: sideways;*/
    font-weight: lighter;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    font-size: 3vw;
    letter-spacing: 0.3vw;
    text-align: right;
}

.time-block {
    margin-left: 0.5vw;
    padding: 0.5vw 1vw;
    border-left: solid 0.15vw #D6BBBC;
}

#event-location {
    font-size: 0.9vw;
    font-weight: lighter;
    font-style: italic;
}

.events span {
    line-height: calc(1vw + 1vh);
}

/*.day::before, .day::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    left: -4vw; top: -1vw;*/
/*    right: -1vw; bottom: -1vw;*/
/*    !*width: calc(100% + 5px);*!*/
/*    !*height: calc(100% + 5px);*!*/
/*    border-radius: 8px;*/

/*    !* Color Palette *!*/
/*    background: linear-gradient(45deg,*/
/*    #15C0E9, #f4f0ed, #0af7cc, #f4f0ed,*/
/*    #0100CB, #ceced7,*/
/*    #6e0dd0, #ceced7, #ED00EB, #f4f0ed,*/
/*    #BE73ED,*/
/*    #15C0E9, #f4f0ed, #3CB9FC, #0af7cc);*/

/*    background-size: 900%;*/
/*    !*opacity: 0.9;*!*/
/*    z-index: -1;*/

/*    !* Animation Speed *!*/
/*    animation: animate 100s linear infinite;*/
/*}*/
/*.day::after {*/
/*    filter: blur(10px);*/
/*    opacity: 0.3;*/
/*}*/

/*!* Animation Code *!*/
/*@keyframes animate{*/
/*    0%{background-position: 0 0;}*/
/*    50%{background-position: 400% 0}*/
/*    100%{background-position: 0 0;}*/
/*}*/
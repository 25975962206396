.cover {
    content: '';
    position: absolute;
    /*margin-top: -330px;*/
    /*background-position-y: 5rem;*/
    height: 20rem;
    width: 100%;
    background-color: #1B1D28;
    z-index: 2;
}

.first {
    position: relative;
    margin-top: -28vh;
    /*margin-top: calc(-5vw - 20vh);*/
    padding: 5% 8vw;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: calc(5vw + 2vh);
    margin-bottom: 4%;
    /*line-height: 10rem;*/
    background: linear-gradient(0deg, rgba(18, 15, 28, 0.6), rgba(13.5, 15, 21, 0.05));
    z-index: 3;
}

.director {
    position: relative;
    padding: 2vw;
    background-color: darkred;
    width: 100vw;

    /* Arrange inside container */
    display: flex;
    column-gap: 3vw;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.dir-name {
    /*font-size: 7vw;*/
    font-size: calc(6vw + 1vh);
    margin-left: -0.5vw;
}

.director img {
    height: 40vw;
}

.vertical-line {
    border-left: solid #1B1D28;
    padding-left: 2.75vw;
    /*background-color: #6e0dd0;*/
}

.vertical-line h3 {
    position: relative;
    font-size: 5vw;
    color: #1B1D28;
    font-weight: lighter;
    margin-bottom: 1vw;
}

.vertical-line p {
    font-size: 1.25vw;
    margin-bottom: 1vw;
    font-weight: lighter;
    /*text-align: justify;*/
}

.director::after {
    content: " ";
    position: absolute;
    top: calc(-1vw - 1vh); bottom: calc(-1vw - 1vh);
    left: calc(-1vw - 1vh); right: calc(-1vw - 1vh);
    background-color: transparent;
    border: calc(0.6vw + 0.2vh) solid darkred;
}

.side-year {
    font-size: calc(10vw + 4vh);
    /*margin: 5%;*/
    line-height: calc(8vw + 6vh);
    text-shadow: 8px 8px 8px black;
    /*color: darkred;*/
    z-index: 3;
}

.second {
    position: relative;
    padding: 0 6vw;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 10vw;
    /*line-height: 10rem;*/
}
.coordinator {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 58vw;
    padding: 4% 4% 1%;
    margin: 0 auto;
    /*background: linear-gradient(0deg, rgba(18,20,28,200), #1B1D28);*/
}

.coordinator img {
    height: 40vw;
    width: 28vw;
    object-fit: cover;
}

.coordinator h1 {
    text-align: center;
    font-size: 3vw !important;
    font-weight: normal;
}

.coordinator h2 {
    text-align: center;
    font-size: 200%;
    font-weight: lighter;
    /*background-color: #6e0dd0;*/
}

.coordinator h3 {
    text-align: center;
    font-size: 1.8vw;
    /*margin-top: -2%;*/
    /*margin-bottom: 4%;*/
    margin:-2% -2% 4%;
    padding: 3% 0 0;
    width: fit-content;
    align-self: center;
    border-top: solid darkred;
    font-weight: lighter;
    text-wrap: none !important;
}


/*#ou { border: 8px solid darkred; }*/

/*#uiuc { border: 8px solid #e64a37; }*/

#ou::before {
    /*height:  fit-content + 2rem;*/
    /*background-color: #6e0dd0;*/
    content: "";
    position: absolute;
    left: -2vw; top: 0;
    width: calc(100% + 3vw);
    height: calc(100% + 3vw);

    border: 6px solid darkred;
}

#ou::after {
    content: "";
    position: absolute;
    left: 0; top: -2vw;
    width: calc(100% + 3vw);
    height: calc(100% + 3vw);

    border: 6px solid darkred;
}

#uiuc::before {
    /*height:  fit-content + 2rem;*/
    /*background-color: #6e0dd0;*/
    content: "";
    position: absolute;
    left: -2vw; top: 0;
    width: calc(100% + 3vw);
    height: calc(100% + 3vw);

    border: 6px solid #e64a37;
}

#uiuc::after {
    content: "";
    position: absolute;
    left: 0; top: -2vw;
    width: calc(100% + 3vw);
    height: calc(100% + 3vw);

    border: 6px solid #e64a37;
}


/*!* Container - Team Members *!*/
.team {
    position: relative;
    margin: 10vw 4vw;
    /*padding: 4vw;*/
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5vw;
    z-index: 2;
    background-color: #1B1D28;
}

/*!* Individual Bios *!*/
.team-member {
    position: relative;
    width: 21vw;
    height: fit-content;
    /*border-radius: 8px;*/
    /*box-sizing: border-box;*/
    text-align: center;
}

.team-member img {
    height: 20vw;
            /*23vw;*/
    width: 15vw;
    /*17vw;*/
    object-fit: cover;
}

.position {

    font-weight: lighter;
    font-size: 1.5vw;
    margin: 0.5vw -2vw;
}

.person {
    position: relative;
    display: flex;
    text-align: center;
    border-top: solid darkred;
    border-bottom: solid darkred;
    width: 60%;
            /*80%;*/
    min-width: fit-content;
    padding: 0 1vw 0.5vw 0;
    gap: 0.3vw;
    /*    !* Center Element*!*/
    transform: translateX(-50%);
    left: 50%;
    /*padding-bottom: 10px;*/
    /*align-items: center;*/
    /*justify-items: center;*/
}

.person img {
    height: 3.5vw;
    width: 3.5vw;
    object-fit: contain;
    margin: auto;
    padding-top: 0.5vw;
    /*background-color: #6e0dd0;*/
    /*justify-self: center;*/
}

.name {
    margin-top: 0.3vw;
    font-size: 1.8vw;
    /*line-height: 2vw;*/
    /*background-color: #3cb9fc;*/
    /*text-align: center;*/
    /*border-top: solid darkred;*/
    /*width: 28rem;*/
    /*margin: 10px auto 3px;*/
}

.academics {
    font-size: 0.95vw;
    /*margin: auto -5vw auto -1vw;*/
    /*background-color: aquamarine;*/
    /*text-align: center;*/
    /*border-bottom: solid plum;*/
    /*width: calc(fit-content + 50vw);*/
    /*padding-bottom: 10px;*/
    /*margin: 10px auto 20px;*/
}

.team-member p {
    /*width: 28rem;*/
    text-align: justify;
    font-size: 0.9vw;
    /*margin: 2vw auto;*/
    padding: 1vw 0.5vw;
    /*background-color: #6e0dd0;*/
}
#host {
    font-weight: 400;
}

#illinois {
    border-top: solid #e64a37;
    border-bottom: solid #e64a37;

}

#mp {
    line-height: 2.5rem;
    margin-bottom: .5rem;
}
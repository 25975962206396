.nav {
    position: fixed;
    top: 0; left: 0;
    width: 100%;
    background-color: transparent;
    /*background: linear-gradient(0deg, transparent, #0F1018);*/
    /*background: linear-gradient(0deg, #1B1D28, #0F1018);*/
    color: #f9f9f9;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    /*padding: 0.5rem 1rem;*/
    padding: 0.6rem 2rem 0 0.6rem;
    font-size: calc(0.84vw + 1vh);
    z-index: 5;
}
.site-name {
    font-size: xx-large;
}

/*.site-name img{*/
/*    !*height: 10vw;*!*/
/*    margin: 1vw;*/
/*    height: calc(3vw + 3vh);*/
/*    padding: 0.05vw;*/
/*    background: radial-gradient(white, white, white, #f4f0ed, #D0c9c3,  white);*/
/*    !*border: solid darkred;*!*/
/*    border-radius: 50%;*/
/*    transform: scale(1.5);*/
/*}*/

.logo-image {
    height: calc(5vw + 5vh);
    margin-bottom: -2.5rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.25rem;
}

.nav ul {
    list-style: none;
    display: flex;
    gap: 1rem;
    height: 3rem;
    /*font-size: 1.1rem;*/
}

.nav li:hover {
    margin: -5px;
    font-size: 1.4rem;
}

.nav li.active {
    border: 1px solid #f4f0ed;

}

/* Mobile Device Formatting */
@media only screen and (max-device-width: 769px) {
    .logo-image {
        height: 4rem;
    }
    .nav a {
        /*padding: 0;*/
    }
    .nav {
        font-size: 0.6rem;
        /*padding-right: 1rem;*/
        padding: 0.2rem 1rem 0 0.2rem;
        gap: 0;
    }
    .nav ul {
        padding-top: 1.4rem;
        text-align: center;
        /*padding-top: 0.4rem;*/
    }
    .nav li:hover {
        /*margin: -;*/
        font-size: 1rem;
    }
}








/*.header {*/
/*    position: fixed;*/
/*    width: 100%;*/
/*    top: 0;*/
/*    left: 0;*/
/*    background-color: transparent;*/
/*    !*z-index: var(--z-fixed);*!*/
/*}*/

/*.nav {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: space-between;*/
/*    position: relative;*/
/*    height: auto;*/
/*    margin: 1rem;*/
/*}*/

/*!*.nav__logo {*!*/
/*!*    !*color: var(--first-color);*!*!*/
/*!*    transition: color 0.4s;*!*/
/*!*    !*font-size: var(--h2-font-size);*!*!*/
/*!*    !*font-family: var(--second-font);*!*!*/
/*!*}*!*/

/*!*.nav__toggle,*!*/
/*!*.nav__close {*!*/
/*!*    font-size: 1.5rem;*!*/
/*!*    !*color: var(--title-color);*!*!*/
/*!*    cursor: pointer;*!*/
/*!*}*!*/

/*!*.nav__cta {*!*/
/*!*    !*background-color: var(--first-color);*!*!*/
/*!*    !*color: var(--title-color);*!*!*/
/*!*    border: none;*!*/
/*!*    border-radius: 4px;*!*/
/*!*    cursor: pointer;*!*/
/*!*    transition: background-color 0.3s ease;*!*/
/*!*    padding: 0.75rem 1.5rem;*!*/
/*!*}*!*/
/*!*.nav__list {*!*/
/*!*    display: flex;*!*/
/*!*    flex-direction: column;*!*/
/*!*    row-gap: 2.5rem;*!*/
/*!*}*!*/

/*!*.nav__link {*!*/
/*!*    !*color: var(--title-color);*!*!*/
/*!*    !*font-weight: var(--font-semi-bold);*!*!*/
/*!*    transition: color 0.4s;*!*/
/*!*}*!*/

/*!*.nav__link:hover {*!*/
/*!*    !*color: var(--first-color);*!*!*/
/*!*}*!*/

/*!*.nav__close {*!*/
/*!*    position: absolute;*!*/
/*!*    top: 1rem;*!*/
/*!*    right: 1.5rem;*!*/
/*!*}*!*/

/*!*.show-menu {*!*/
/*!*    right: 0;*!*/
/*!*}*!*/
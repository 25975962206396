/*!* Default Styling *!*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    /*font-family: "Helvetica Neue";*/
}

@media only screen and (max-device-width: 769px) {
    /*h1{font-size: 3rem !important;}*/
    /*span{font-size: 1rem !important;}*/
    /*h6 {font-size: 1rem !important;}*/
    .footer {
        flex-direction: column;
        height: 1vh !important;
        margin: 15% 1vh !important;
        border-width: 0.6vh !important;
        border-radius: 100%;
        /*width: 99%;*/
        /*justify-self: center !important;*/
        /*padding: 0;*/
        /*gap: 0;*/
    }
    .foot-section {
        width: 100vw !important;
        font-size: 1.3vh !important;
        line-height: 2.5vh !important;
        text-align: left !important;
        margin: -6vh 0 !important;
        padding-left: 1.5vh;
        /*background: #6e0dd0;*/
        /*padding: 0;*/
        /*gap: 0;*/
    }
    .footer img {
        align-self: flex-end;
        height: 10vh !important;
        margin-right: -1vh;
    }

}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: #1B1D28;  /* Original Grey */
    /*background-color: #15284c;     !* cohost blue*!*/
    /*background-color: #111423;  !*darker bolder bluer*!*/
    /*background-color: #100c14;  !* Darkest almost black *!*/
    color: #f9f9f9;
}

html {
    scroll-behavior: smooth;
}

/*!* Section Titles *!*/
h1 {
    font-size: calc(7vw + 0.5vh) !important;
}
h2 {
    /*margin: 50px;*/
    /*text-align: center;*/
    /*font-size: 5rem;*/
    /*z-index: 2;*/
}
/*h6 {*/
/*    font-size: 1.5rem;*/
/*    font-weight: lighter;*/
/*    letter-spacing: 0.2rem;*/
/*}*/
/*h3 {*/
/*    margin: 0 45px;*/
/*    text-align: center;*/
/*    font-size: 2rem;*/

/*}*/

.footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(4vw + 4vh);
    margin: 10rem 0 1rem;
    padding: 0 5vw;
    gap: 5vw;
    /*background-color: #f9f9f9;*/
    /*background-color: floralwhite;*/
    /*!*background-color: #f4f0ed;*!              nilla white*/
    /*background-color: #545563;*/
    /*background-color: #8B0313;*/
    /*background-color: #841617;*/
    /*background-color: #A25C66;*/
    background: linear-gradient(0deg, #5A0000, darkred, firebrick, darkred, #5A0000);
    text-shadow: 1px 1px black;
    /*border: solid darkred;*/
    /*border-width: 0.3rem;*/
}
.footer img {
    height: calc(6vw + 6vh);
    padding: 0.5vw;
    background: radial-gradient(white, white, white, #f4f0ed, #D0c9c3,  white);
    /*border: solid darkred;*/
    border-radius: 50%;
    transform: scale(1.5);
    /*clip-path: circle(40%);*/
}

.foot-section {
    width: 40%;
    /*background-color: darkred;*/
    /*font-size: 1.15rem;*/

    /*font-size: 1.22vw;*/
    font-size: calc(1vw + 0.7vh);

    font-weight: lighter;
    line-height: 2vw;
    text-align: center;
}

.leaflet-container {
    height: 20vw;
}
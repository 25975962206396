.menu {
    position: relative;
    padding: 10%;
    font-size: 4rem;
    z-index: 5;
    text-align: center;
    background-color: #1B1D28;
}

.menu li {
    padding-left: 30%;
    text-align: left;
    list-style: inside circle;
}
/*!* Countdown Base Element *!*/
.countdown {
/*    !* Positioning *!*/
    position: absolute;
    top: calc(33vw + 9vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;

/*    !* Center Element*!*/
/*    transform: translateX(-50%);*/
/*    left: 50%;*/
/*    top: calc(3vw + 7vh);*/

    /* Size */
    width: fit-content;
    /*min-width: 37rem;*/
    padding: 2vh 0;

    /* Style */
    color: white;
    text-shadow: 1.5px 1.5px 3px black;
    border-radius: 15px;

    /* Adjust Darkness */
    /*background: black;*/
}

@media only screen and (max-device-width: 769px) {
    .countdown{top:6vh !important;}
    .countdown h4 {font-size: 1.8rem !important;}
    .countdown h6 {font-size: 0.9rem !important;}
    .countdown span {
        font-size: 3rem !important;
        margin: 0.5rem 0.5rem 0 !important;
        width: 30px !important;
    }
    .time-label span {
        width: 80px !important;
        font-size: 0.6rem !important;
    }
    /*.countdown::after {*/
        /*filter: blur(15px) !important;*/
        /*opacity: 0.6 !important;*/
    /*}*/
}

/*!* Element Header *!*/
.countdown h4 {
    position: relative;
    margin-top: calc(-3vw + -3vh);
    padding-bottom: calc(1vw + 1vh);
    text-align: center;
    font-size: calc(1vw + 2vh);
    font-weight: lighter;
}
.countdown h6 {
    /*font-size: calc(1vw + 0.5vh);*/
    font-weight: lighter;
    letter-spacing: 0.2rem;
}

/*!* Time Rendered *!*/
.countdown span{
    display: inline-flex;
    justify-content: center;
    margin: 0.5vw 0.7vw 0;
    width: 3.02vw;
    font-size: calc(2vw + 2vh);
}

/* Labels */
.time-label span{
    display: inline-flex;
    justify-content: center;
    width: calc(7vw + 1vh);
    font-size: calc(0.4vw + 0.6vh);
    font-weight: lighter;
    letter-spacing: 0.2vw;
}

.early-open {
    position: absolute;
    text-align: center;
    margin: -67px -47%;
    /*transform: translateX(30%);*/
    padding: 2.5rem 0;
    height: 8rem;
    width: 35rem;
    background: linear-gradient(darkred, #6C1112);
    font-size: 2.25rem;
    /*font-weight: lighter;*/
    border-radius: 15px;
    /*border: 3px solid #6C1112;*/
    color: white;
    text-decoration: none;
}

.early-open:hover {
    background-image: linear-gradient(darkred, #e64a37);
    font-size: 2.35rem;
    /*height: 9rem;*/
    /*width: 36rem;*/
    /*margin: -75px -48.5%;*/
    /*border-color: #1482D0;*/
    text-decoration: none;
}

/*.early-open:focus {*/
/*    box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;*/
/*    outline: none;*/
/*}*/


.countdown::before {
    content: "";
    position: absolute;
    left: -2px;
    top: -2px;
    width: calc(100% + 5px);
    height: calc(100% + 5px);
    border-radius: 12px;
    background: linear-gradient(0, #5A0000, darkred, #5A0000);
    z-index: -1;
}

.countdown::after {
    content: "";
    position: absolute;
    left: -2px; top: -2px;
    width: calc(100% + 5px);
    height: calc(100% + 5px);
    border-radius: 10px;

    /* Color Palette */
    background: linear-gradient(45deg,
    #C3C7CA, #C3C7CA, #767683, #aeb2bb,
    #ceced7, #aeb2bb, #797785,
    #C3C7CA, #7b7b88, #C3C7CA, #C3C7CA);

    background-size: 900%;
    opacity: 0.4;
    filter: blur(10px);
    /*filter: blur(30px);*/
    z-index: -2;

    /* Animation Speed */
    animation: animate 200s linear infinite;
}

/* Animation Code */
@keyframes animate{
    0%{background-position: 0 0;}
    50%{background-position: 400% 0}
    100%{background-position: 0 0;}
}
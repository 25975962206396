
.previous-year {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 3vw 4vw;
    gap: 2vw;
    border-radius: 12px;
    overflow: hidden;
    align-items: stretch;
    justify-content: space-between;
}

#y2024 {background: linear-gradient(90deg, #6e0dd0, transparent, transparent);}
#y2023 {background: linear-gradient(90deg, #50002A, transparent, transparent);}
#y2022 {background: linear-gradient(90deg, coral, transparent, transparent);}
#y2021 {background: linear-gradient(90deg, blue, transparent, transparent);}
#y2020 {background: linear-gradient(90deg, #e65f1b, transparent, transparent);}
#y2019 {background: linear-gradient(90deg, black, transparent, transparent);}
#smaller-fit {font-size: 1.25vw;}

.year-info {
    padding: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 35%;
}

.year-info h1 {
    font-size: 2.5vw;
    margin: 0 0 1vw;
    text-shadow: 1px 1px 3px black;
}

.year-info h2 {
    font-size: 2vw;
    font-weight: 300;
    margin: 0 -1vw 1.5vw 0;
}

.year-info p {
    font-size: 1.5vw;
    line-height: 1.6;
    margin-bottom: 1vw;
    font-weight: 100;
}

.image-gallery {
    flex-grow: 1;
    overflow: hidden;
    max-width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}

.image-gallery-slide .image-gallery-image {
    width: 100%;
    height: auto;
    max-height: 50vh;
    object-fit: cover;
}

.image-gallery-left-nav, .image-gallery-right-nav {
    padding: 0.5rem;
    opacity: 0.5;
}

.image-gallery-left-nav:hover, .image-gallery-right-nav:hover {
    color: #f9f9f9;
    opacity: 1;
}
.this-year h1 {
    text-align: center;
    font-weight: lighter;
    font-size: 13vw !important;
    text-shadow: 3px 3px 5px black;
    letter-spacing: 3vw;
    margin: 0 auto 1vw ;
}

.this-year {
    position: relative;
    position: relative;
    margin-top: calc(-90vw + 5vh);
    z-index: 3;
}

.location-details {
    margin: 5vw 4% 10vw 1%;
}

.location-details img {
    position: relative;
    clip-path: inset(0 0 0 7vw);
    width: 90vw;
}

#groupMe {
    margin: calc(-33vw - 10vh) 8% 0;
    /*padding-left: 5vw;*/
}
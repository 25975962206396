.contain-contacts {
    position: relative;
    margin-top: -82vw;
    padding: 0 5rem 0 7vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    gap: 15rem;
    background: transparent;
    z-index: 2;
}

.contact-method {
    color: #f4f0ed;
    width: fit-content;
    text-align: center;
}

.contact-method h2 {
    padding-top: 0.5vw;
    font-size: 4vw;
    font-weight: lighter;
}

.contact-method h3 {
    font-size: 2vw;
    font-weight: lighter;
}

.contact-method img {
    margin: 5% 0;
    height: 18vw;
}
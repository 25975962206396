.faq {
    /* Flex to list articles vertically */
    position: relative;
    /*margin-top: calc(5vw + 5vh);*/
    height: fit-content;
    width: fit-content;
    padding: 0 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
}

.faq h1 {
    font-size: calc(2.5vw + 1.5vh) !important;
    font-weight: lighter;
}

.faq-set {
    width: calc(35vw + 10vh);
    min-width: 350px;
    margin-bottom: 1rem;
}

.question {
    /*background: #143d59;*/
    background: #2C2F3A;
    /*color: whitesmoke;*/
    font-size: calc(1vw + 1vh);
    padding: calc(0.5vw + 0.5vh);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
}
.question span {
    display: inline-block;
}
.arrows-container {
    margin-left: -88%;
    padding: 0.4vw 1vw 0.4vw 92%;
    /*background: #6e0dd0;*/
}
.answer {
    display: none;
    }

.close {
    /*display: none;*/
    color: darkred;
    font-size: 0;
}

.expand {
    font-size: 2rem;
}

.show-answer .answer {
    display: block;
    font-size: calc(1vw + 0.5vh);
    margin-top: 1vw;
    padding: 1.5vw;
    background: #f4f0ed;
    color: #1B1D28;
    /*border: solid 10px darkred;*/
    border-radius: 10px;

    /*background: transparent;*/
}
.show-answer .close {
    display: inline;
    font-size: 2rem;
}
.show-answer .expand {
    display: none;
}

/*.show-answer .question {*/
/*    border: solid darkred;*/
/*}*/
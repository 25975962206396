form {
    margin: -6vw auto 1vw;
    padding: 6vw 5vw 0 ;
    /*padding: 6vw 3vw 0 ;*/
    background-color: #1B1D28;
    /*background-color: rgba(0, 0, 0, 0.5);*/
    border: solid 0.1vw #aea49c;
    border-radius: 10px;
}

.formRow {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    /*background-color: #6e0dd0;*/
}

.form-entry {
    /*margin: 0 1vw;*/
}

input {
    width: 14.2vw;
}
.formInput { padding: 1vw; }

.formRow textarea { width: 30vw; }

.errorMessage { font-size: 14px; }

.submit-btn {
    padding: 15px 50px;
    border-radius: 10px;
    margin-bottom: 25px;
}

/*@media (max-width: 768px) {*/
/*    .submit-message {*/
/*        width: 125px;*/
/*        margin-left: 200px;*/
/*    }*/
/*}*/
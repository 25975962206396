/*!* Floating Text Over Header *!*/
.thankyou {
    position: absolute;
    /*top: 25rem; right: 5rem;*/
    top: 28vw; right: 6vw;
    padding: 2vw 2.4vw 3vw;
    width: 48vw;
    /*text-align: justify;*/
    /*width: 50rem;*/
    /*transform: translateY(-50%);*/

    /* Darkened, transparent background */
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    z-index: 3;

}

.thankyou p{
    padding-left: 3vw;
    font-size: 1.75vw;
    font-weight: lighter;
    /*font-style: oblique;*/
    line-height: 2.25vw;
    /*text-align:;*/
}

.thankyou h5 {
    /*font-size: 2.2rem;*/
    font-size: 2vw;
    padding: 0 0 1vw 0;
    text-align: center;
}

@media only screen and (max-device-width: 769px) {
    .sponsor-page h2{font-size: 4.4vh !important;}
    .thankyou {
        top: 63vw;
        transform: translateX(-50%);
        left: 50%;
        width: 95vw;
        background-color: rgba(0, 0, 0, 0.6);

    }
    .thankyou p{
        padding-left: 3vw;
        font-size: 3.5vw;
        line-height: 4.25vw;
    }

    .thankyou h5 {
        font-size: 4.5vw;
        font-weight: normal;
        padding: 2vw 0 3vw;
    }

    .feature-diamond {margin: -96vw auto 20vw !important;}

    .feature {width: 45vw !important;}

    #year {
        font-size: 25vw !important;
        line-height: 24vw !important;
    }

    .feature-diamond {column-gap: 6vw !important;}
}

.sponsor-page::after {
    position: absolute;
    content: "";
    background: linear-gradient(0deg, #1B1D28, rgba(27, 29, 40, 0.99), rgba(0, 0, 0, 0.4), transparent);
    z-index: 0;
    top: 25vw;
    height: 170vw;
    width: 100vw;

}

.sponsor-page h2 {
    margin: 5vh 0 2vw;
    text-align: center;
    z-index: 2;
    font-size: calc(6vw + 3vh);
    font-weight: normal;
    text-shadow: 3px 3px 2px black;
    width: 100%;
    /*z-index: 7 !important;*/
}

.feature-diamond {
    position: relative;
    margin-top: -102vw;
    top: 0;
    /*padding-top: 3rem;*/
    display: flex;
    column-gap: 8vw;
    justify-content: center;
    align-items: center;
    /*margin-bottom: 3vw;*/
    z-index: 1;
}

#year {
    font-size: 18vw;
    font-weight: bold;
    line-height: 17vw;
    /*padding-top: calc(4vw + 2vh);*/
}

/*!* Diamond Level Sponsor *!*/
.feature {
    position: relative;
    width: 35vw;
    background-color: #f9f9f9;
    z-index: 3;
}

/*!* Container - All Sponsors *!*/
.sponsors {
    position: relative;
    margin: 15vw 6vw;
    padding: 0 1vw 5vw;
    background-color: darkred;

    /* Arrange inside container */
    display: flex;
    flex-wrap: wrap;
    column-gap: 5%;
    row-gap: 2rem;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.sponsors::after {
    content: " ";
    position: absolute;
    /*margin: 3rem 6rem;*/
    /*height: 90%;*/
    /*width: 90%;*/
    top: -25px; bottom: -25px;
    left: -25px; right: -25px;
    background-color: transparent;
    border: solid darkred;
    border-width: 11px;
}

/*.sponsors h2 {*/
/*    font-size: 6rem;*/
/*    width: 100%;*/
/*    */
/*}*/

/* Individual Sponsor Logo */
.sponsors img {
    position: relative;
    height: calc(6vw + 6vh);
    /*width: 300px;*/
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);*/
}

#placeholder {
    width: 700px;
    box-shadow: none;
}

.become-sponsor {
    position: relative;
    /*color: #f4f0ed;*/
    /*background: #6e0dd0;*/
    margin: 5vw auto 7vw;
    text-align: center;
    z-index: 1;
    font-size: calc(6vw + 3vh);
    font-weight: normal;
    text-shadow: 3px 3px 2px black;
    width: 100%;
   }


/*!* Container - Sponsor Packages *!*/
.sponsor-tiers {
    position: relative;
    margin: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    z-index: 2;
    /*background-color: #b30000;*/
}

/*!* Container - Individual Package *!*/
.border {
    position: relative;
    flex: 1;
    width: 7rem;
    height: fit-content;
    min-width: 300px; /* Ensures each box has a minimum width */
    max-width: 350px; /* Sets a maximum width for each box */
    border-radius: 8px;
    box-sizing: border-box;
    text-align: center;
}

.diamond {

    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 2;
    /*background-color: #b30000;*/


    /*position: relative;*/
    /*display: flex;*/
    /*!*align-self: flex-end;*!*/
    /*flex-direction: row;*/
    /*width: 17rem;*/
    /*height: fit-content;*/
    /*min-width: 300px; !* Ensures each box has a minimum width *!*/
    /*max-width: 350px; !* Sets a maximum width for each box *!*/
    /*border-radius: 8px;*/
    /*box-sizing: border-box;*/
    /*text-align: center;*/
}

/*.diamond-split {*/
/*    position: relative;*/
/*    flex: 1;*/
/*    width: 7rem;*/
/*    height: fit-content;*/
/*    !*min-width: 300px; !* Ensures each box has a minimum width *!*!*/
/*    !*max-width: 350px; !* Sets a maximum width for each box *!*!*/
/*    box-sizing: border-box;*/
/*    text-align: center;*/
/*}*/

/*!* Individual Package Information *!*/
.tier {
    position: relative;
    /*width: 100%;*/
    /*height: 100%;*/
    border-radius: 8px;
    padding: 10px;
    /* Creates grey gradient */
    background: linear-gradient(0deg, #1B1D28, #303346);
    z-index: 2;
}

#diamond-tier {
    display: flex;
    /*justify-content: space-between;*/
    justify-content: center;
    /*align-items: flex-start;*/
    gap: 2rem;
    flex-wrap: wrap;
    /*justify-content: center;*/
    padding-left: 2rem;
    padding-right: 2rem;
}
.diamond-split {
    /*width: 12rem;*/
    /*margin-top: -2rem;*/
    /*background-color: aqua;*/
    /*padding-top: -*/
    text-align: center;
    justify-items: center;
    align-items: center;
}
#diamond-tier img {
    height: 14rem;
    margin: -4rem 0 -3.5rem 0;
}

/* Tier Level Title */
.border h4 {
    margin: 1rem;
    font-size: 2rem;
}
#gold h4{color: #fce29b;}
#silver h4{color: #C3C7CA;}
#bronze h4{color: #C79275}
.diamond h4{
    margin: 1rem;
    padding-top: 2rem;
    font-size: 2rem;
    color: White;
}

.price {
    font-size: 1.9rem;
    color: #b30000;
}
/* Style Perks Lists */
.tier li {
    /*text-align: left;*/
    padding: 1rem 0;
    list-style: none;
    font-size: 1.5rem;
    text-align: center;
}

#diamond-tier li{
    text-align: left;
    /*padding-left: 2rem;*/
    padding-right: 2rem;
}

#darkaccess {
    color: #303346;
}

#gold::before, #gold::after {
    content: "";
    position: absolute;
    /*width: calc(200px);*/
    /*height: 200px;*/
    left: -2px; top: -2px;
    right: -2px; bottom: -2px;
    border-radius: 12px;
    /* Color Palette */
    background: linear-gradient(45deg,
    #fce29b, #fce29b,#cfae62, #FFFCD9,
    #be9b52, #E9CE87, #9E7933,
    #cfae62, #FFFCD9, #be9b52, #fce29b, #fce29b);
    background-size: 900%;
    /*opacity: 0.8;*/
    z-index: -1;
    /* Animation Speed */
    animation: animate 100s linear infinite;
}
#gold::after {
    filter: blur(7px);
    opacity: 0.2;
}

/*!* Silver Boarder *!*/
#silver::before , #silver::after{
    content: " ";
    position: absolute;
    left: -2px; top: -2px;
    right: -2px; bottom: -2px;
    border-radius: 12px;
    /* Color Palette */
    background: linear-gradient(45deg,
    #C3C7CA, #C3C7CA, #767683, #aeb2bb,
    #ceced7, #aeb2bb, #797785,
    #C3C7CA, #7b7b88, #C3C7CA, #C3C7CA);
    background-size: 900%;
    /*opacity: 0.8;*/
    /*width: calc(100% + 5px);*/
    /*height: calc(100% + 5px);*/
    /* Set position behind block */
    z-index: -1;
    /* Animation Speed */
    animation: animate 100s linear infinite;
}
#silver::after {
    filter: blur(7px);
    opacity: 0.2;
}

/*!* Bronze Boarder *!*/
#bronze::before, #bronze::after {
    content: "";
    position: absolute;
    left: -2px; top: -2px;
    right: -2px; bottom: -2px;
    border-radius: 12px;

    /* Color Palette */
    background: linear-gradient(45deg,
    #C79275, #C79275, #96583A,
    #B47457, #D6BBBC,
    #96583A, #B47457,
    #B47457, #96583A, #C79275, #C79275);

    background-size: 990%;
    /*opacity: 0.8;*/
    z-index: -1;
    /* Animation Speed */

    animation: animate 100s linear infinite;
}

#bronze::after {
    filter: blur(7px);
    opacity: 0.2;
}

.glow::before, .glow::after {
    content: "";
    position: absolute;
    left: -2px; top: -2px;
    width: calc(100% + 5px);
    height: calc(100% + 5px);
    border-radius: 12px;

    /* Color Palette */
    background: linear-gradient(45deg,
    #15C0E9, #f4f0ed, #0af7cc, #f4f0ed,
    #0100CB, #ceced7,
    #6e0dd0, #ceced7, #ED00EB, #f4f0ed,
    #BE73ED,
    #15C0E9, #f4f0ed, #3CB9FC, #0af7cc);

    background-size: 900%;
    /*opacity: 0.9;*/
    z-index: -1;

    /* Animation Speed */
    animation: animate 100s linear infinite;
}
#diamond::after {
    filter: blur(10px);
    opacity: 0.3;
}

/* Animation Code */
@keyframes animate{
    0%{background-position: 0 0;}
    50%{background-position: 400% 0}
    100%{background-position: 0 0;}
}


/*#feature-glow::before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    left: 50%;*/
/*    margin-left: calc(-15rem - 1px);*/
/*    margin-top: 3.75rem;*/
/*    !*margin-left: -16.5rem;*!*/
/*    width: 30rem;*/
/*    height: 30rem;*/
/*    !*width: 33rem;*!*/
/*    !*height: 33rem;*!*/
/*    background: linear-gradient(45deg,*/
/*    #15C0E9, #0af7cc, #0100CB, #ceced7,*/
/*    #6e0dd0, #ceced7, #ED00EB, #BE73ED,*/
/*    #15C0E9, #3CB9FC, #0af7cc);*/
/*    background-size: 950%;*/
/*    !*border-radius: 50px;*!*/
/*    opacity: 0.5;*/
/*    z-index: 1;*/
/*    !* Animation Speed *!*/
/*    animation: animate 100s linear infinite;*/
/*}*/
/*!* Glow Effect *!*/
/*#feature-glow::after{*/
/*    filter: blur(30px);*/
/*    opacity: 0.8;*/
/*    !* Smaller opacity: 0.1  = small glow *!*/
/*    !* Larger opacity:  0.95 = large glow *!*/
/*}*/
/*!* Gold Border *!*/


/*.sponsor-tiers > :nth-child(2) {*/
/*    width: 50%;*/
/*    z-index: 10;*/
/*    box-shadow: 0 5px 10px rgba(0, 0, 0, 25%);*/
/*}*/

/* tablet breakpoint */
/*@media (min-width:768px) {*/
/*    .sponsor-tiers {*/
/*        width: calc(100%/4)*/
/*        !*display: grid;*!*/
/*        !*grid-auto-rows: 1fr;*!*/
/*        !*grid-template-columns: 1fr 1fr 1fr 1fr;*!*/
/*    }*/
/*}*/
/*!* Base Header Setting *!*/
.header {
    /* Text */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Image */
    position: relative;
    background-size: contain;
    background-position: center;
    background-attachment: fixed;

    background-repeat: no-repeat;
    animation: fadeInBackground 2s;
}

@keyframes fadeInBackground {
    from {opacity: 0}
    to {opacity: 1}
}

/* Mobile Device Formatting */
@media only screen and (max-device-width: 769px) {
    .header {
        background-size: 180%;
    }
    .header::before {
        height: 150vh !important;
    }
    .header h1 {
        line-height: 3rem;
    }
    #mdm {
        padding-top: 1.5vh;
        height: 40vh !important;
        background-position-y: -5vh !important;
    }
    #sponsorship {
        background-position-x: -5vh;
        background-size: 115vw !important;
        padding: 7vh 14vh !important;

    }
    #sponsorship {
        /*background-position-y: 5vh !important;*/
        height: 200vw !important;
        background-size: 110vw !important;
        padding: 27vw 26.5vw !important;
    }
}

/*!* Darkness Tint for Header Images *!*/
.header::before {
    position: absolute;
    top: 0; left: 0;
    height: 150vw;
    /*height: calc(140vw + 5vh);*/
    width: 100%;
    content: '';
    /* Overlay Header */
    z-index: 1;
    /* Control Darkness */
    background: linear-gradient(0deg, rgba(27, 29, 40, 0.99), rgba(27, 29, 40, 0.9), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
    /*background: linear-gradient(0deg, #1B1D28, rgba(27, 29, 40, 0.99), rgba(0, 0, 0, 0.4), transparent);*/

}

/*!* Base Text Element *!*/
.header h1 {
    /* Basic Positioning */
    width: fit-content;
    display: flex;
    flex-direction: column;
    z-index: 2;
    /* Center Text */
    margin: 0 -50%;
    left: 50%;
    /* Text Styling */
    color: white;
    font-weight: normal;
    text-shadow: 3px 3px 5px black;
    opacity: 1;
}

.next-line {
    letter-spacing: 0.095em;
    margin: auto;
    padding-left: 10px;
}

/*!* Specific Page Header Settings *!*/
#mdm {
    /*height: 52vw;*/
    height: 150vw;
    background-size: 108%;
    background-position-y: 0;
    text-align: center;
    margin-bottom: -131vw;

    padding-top: 0;
    /*align-content: ;*/
}


#sponsorship {
    /* Background Position */
    background-position-y: 0;
    height: 160vw;
    background-size: 100vw;
    /*top: 0;*/

    /* Font Position */
    font-size: 2.45rem;
    /*padding: 13rem 4rem;*/
    /*padding: 13rem 29% 0 20%;*/
    padding: 8vw 28vw;
    /*margin-top: -2=vw;*/
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    /*line-height: 28vw;*/
}

#team {
    /* Background Position */
    background-position-y: calc(-4vw - 1vh);
            /*-4vh;*/
    /*height: 700px;*/
    height: calc(30vw + 20vh);

    padding: 7vw 0;
    flex-direction: column;
    justify-content: flex-start;
    /*align-items: flex-end;*/

    /* Text Position */
    font-size: 4rem;
    /*padding-left: 10%;*/
    /*margin-top: -20%;*/
    /*top: -50%;*/
    /*left: 50%;           !* Centers the text horizontally *!*/
    /*transform: translate(-50%, 20%);*/
    transform: translateY(0);
    /*z-index: 3;*/
}

#contact {
    /* Background Position */
    background-position-y: 0;
    height: 93vw;
    background-size: 175vh;
}

#explore {
    /* Background Position */
    background-position-y: -23rem;
    height: 300px;
    background-color: darkred;

    /* Text Position */
    font-size: 2.25rem;
    padding-top: 1rem;
    padding-left: 1rem;
}

#info {
    background-position: center calc(5vh - 5vw);
    background-size: calc(100vw + 50vh);

    /*background-position: center 5vw;*/
    /*background-size: calc(90vw + 10vh);*/
    height: 90vw;
    /*margin-bottom: -20vw;*/

    /*padding-bottom: 30%;*/
    /*font-weight: lighter !important;*/
    /*font-size: 15vw !important;*/
    /*letter-spacing: 2vw;*/
}





/* Font Lato */
/*font-family: "Lato", sans-serif;*/
/*font-style: normal;*/
/*https://www.makeuseof.com/tag/free-google-fonts-presentations/*/